.passfree-grad{
    background: linear-gradient(90deg, rgba(169,68,119,1) 0%, rgba(89,98,151,1) 100%);
}
.Toastify__toast-body{
    white-space: pre-line;
}
.Toastify__toast-body ul{
    list-style-type: circle;
}
small.small-xs{
    font-size: 68%;
}
